<template>
  <div class="circle">
    <img class="head-banner" :src="BannerSrc" alt="">
    <van-list v-model="loading" :finished="finished" @load="getCommunityList">
      <Vue-flex-waterfall ref="waterFall" col="2" col-spacing="15" :break-by-container="false">
        <circle-item class="circle-item" v-for="(item,index) in circleList"
                     @click.native="goCircleDetails(item.id,item.picUrl)" :index="index" :key="index" :itemData="item"/>
      </Vue-flex-waterfall>
    </van-list>
    <van-empty v-if="circleList.length===0" description="暂无圈子数据"/>
  </div>
</template>

<script>
  import untils from "@/until/until"
  import wxLogin from '@/until/wxLogin.js'
  import circleItem from './components/circleItem'
  import VueFlexWaterfall from 'vue-flex-waterfall'

  export default {
    name: "homeCircle",
    components: {
      circleItem,
      VueFlexWaterfall
    },
    data() {
      return {
        loading: false,
        finished: false,
        page: {
          pageNum: 1, // 当前页数
          pageSize: 15, // 每页显示多少条
        },
        circleList: [],
        leftList: [],
        rightList: [],
        array: [], //定义空数组存储元素高度
        BannerSrc: ''
      };
    },
    async mounted() {
      if (!localStorage.sessionKey && untils.isWechat()) {
        const res = await wxLogin.getWechatAppId()
        await wxLogin.wxLogin(window.location.href, res.data.appId)
      } else {
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        if (!UserInfo.phone || UserInfo.phone === null || UserInfo.phone === '') {
          // this.$router.push('/login?type=2')
          if (untils.isWechat()) {
            const res = await wxLogin.getWechatAppId()
            await wxLogin.wxLogin(window.location.href, res.data.appId)
          }
        }
      }

      this.getBanner()
    },
    methods: {
      getHeight() {
        this.wfHeight = (document.documentElement.clientHeight - 120) + "px"
      },
      getCommunityList() {
        this.$api.home.communityList(this.page, res => {
          if (res.code === 200) {
            if (res.rows.length === 0) {
              this.finished = true
              return false
            }
            this.circleList = this.circleList.concat(res.rows)
            this.page.pageNum++
            // 加载状态结束
            this.loading = false
            this.$refs.waterFall.updateOrder()
          }
        });
      },
      goCircleDetails(id, picUrl) {
        this.$router.push('/circleDetails?id=' + id + '&picUrl=' + picUrl)
      },
      //获取banner
      getBanner() {
        this.$api.home.bannerList({
          type: 1
        }, res => {
          if (res.code == 200) {
            this.BannerSrc = res.rows[0].picUrl
          }
        })
      }

    }
  };
</script>
<style lang="less" scoped>
  .circle {
    padding: 28px 30px 30px;
    width: 100%;
    box-sizing: border-box;
    background-color: #F6F7F9;
    min-height: 100vh;

    .head-banner {
      width: 690px;
      height: 240px;
      object-fit: cover;
      border-radius: 15px;
      margin-bottom: 30px;
      opacity: 1;
    }

    .circle-item {
      margin-bottom: 24px;
    }

  }

  .vue-waterfall /deep/ .vue-waterfall-column {
    width: 336px !important;
  }

  .vue-waterfall /deep/ .vue-waterfall-column {
    margin-right: 16px;
  }

  .vue-waterfall /deep/ .vue-waterfall-column:last-of-type {
    margin-right: 0px;
  }

  .circleList /deep/ .van-list__loading {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
</style>
